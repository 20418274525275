
import { defineComponent, onMounted, ref, watch } from "vue";
import { CaretRightOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "App",
  props: {
    data: {
      type: Array
    },
  },
  setup(props) {
    const visible = ref(false);
    const isFirst = ref(false);
    watch(() => props.data, (val) => {
      if (val && val.length > 0 && !isFirst.value) {
        visible.value = true;
      } else if (val && val.length === 0 && !isFirst.value) {
        visible.value = false;
      }
    },{
      immediate: true
    });
    const handleToggle = () => {
      visible.value = !visible.value;
      isFirst.value = true;
    };
    return {
      handleToggle,
      visible,
      isFirst
    };
  },
  components: {
    CaretRightOutlined,
  },
});
