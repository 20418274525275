
import { computed, defineComponent, reactive, ref, toRefs, watch, nextTick } from "vue";
import {ApprovealTableData} from "../types";
import useApprovalMain from "@/hooks/payment/Mgmt/PendingApproval/useApprovalMain";
import {ColumnProps} from "ant-design-vue/es/table/interface";
import { useRouter } from 'vue-router'
import {message} from "ant-design-vue";
import ApprovalModal from "@/views/Payment/components/approvalModal.vue";
import { useStore } from 'vuex'
import { elementSize } from "@/utils";
import { toThousands } from "@/utils/approve";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import {Modal} from "ant-design-vue";
// import { UpOutlined } from '@ant-design/icons-vue';
import ApproveSteps from "@/views/Payment/components/ApproveSteps.vue";
import CollapseWrapper from "@/views/Payment/components/CollapseWrapper.vue";
type Key = ColumnProps['key'];

export default defineComponent({
  name: 'paymentApproveMgmt',
  components: {
    ApprovalModal,
    ApproveSteps,
    CollapseWrapper
    // UpOutlined
  },
  props: {},
  setup() {
    const penddingAppNum = ref(0)
    const tabName1 = ref<string>("Pending Approveal ")
//#region [引用useApprovalMain.ts相关]
    const { columnsDealer, columnsMBAFCandMBLC,state,
      tableDataMBAFCandMBLC,tableDataDealer,
      getApprovealTableDataMBAFCandMBLC,getApprovealTableDataDealer,totalAFC,totalD,handleApprove,handleReject,downloadDealerPayoutCodeFile,downloadAFCPayoutCodeFile,
      approveVisible, approveClick, approveList} = useApprovalMain()
//#endregion
//#region [pending paymentToDealer列表查询功能区]
    const store = useStore()
    const tableDataD = ()=>{
      state.userName = store.state.user.fulnm
      if   (state.userName.indexOf('Sales')!=-1){
        state.userName = 'OEM IT Admin'
      }
      state.status = 'DEALER'
      getApprovealTableDataDealer()
    }
    tableDataD()
//#endregion

//#region [pending paymentToMBAFC&MBLC列表查询功能区]
    const  tableDataAfc = () =>{
      state.userName = store.state.user.fulnm
      if   (state.userName.indexOf('Sales')!=-1){
        state.userName = 'OEM IT Admin'
      }
      state.status = 'AFCANDLC'
      getApprovealTableDataMBAFCandMBLC()
    }
    tableDataAfc()
//#endregion

//#region [动态计算审批总数-pending Tab上显示的数]
    const { commit } = useStore()
    watch([totalD, totalAFC], (newV: number[]) => {
      commit('payment/updateApprovealTableCount', newV[0] + newV[1])
    }, { immediate: true })
//#endregion
//#region [定义rowSelectionD和rowSelectionAfc]
    const rowSelectionD = reactive<{ selectedRowKeys: Key[]; selectedRowData: ApprovealTableData[]; onChange: Function;columnWidth: string}>({
      onChange: (selectedRowKeys: Key[], selectedRows: ApprovealTableData[]) => {
        rowSelectionD.selectedRowKeys = selectedRowKeys;
        rowSelectionD.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: '30px'
    })
    const rowSelectionAfc = reactive<{ selectedRowKeys: Key[]; selectedRowData: ApprovealTableData[]; onChange: Function;columnWidth: string}>({
      onChange: (selectedRowKeys: Key[], selectedRows: ApprovealTableData[]) => {
        rowSelectionAfc.selectedRowKeys = selectedRowKeys;
        rowSelectionAfc.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: '30px'
    })
//#endregion
//#region [Reject按钮和Approve按钮功能区]
    const approvalModalVisible = ref<boolean>(false);
    const approvalType = ref<string>('approve');
    const selectedRowKeysAll = computed(() => {
      return [...rowSelectionD.selectedRowKeys, ...rowSelectionAfc.selectedRowKeys]
    })
    const selectedRowsAll = computed(() => {
      return [...rowSelectionD.selectedRowData, ...rowSelectionAfc.selectedRowData]
    })
    const clearSelection = () => {
      rowSelectionD.selectedRowKeys = [];
      rowSelectionD.selectedRowData = [];
      rowSelectionAfc.selectedRowKeys = [];
      rowSelectionAfc.selectedRowData = [];
    };
    const selectedRowData = computed(() => {
      return [...rowSelectionD.selectedRowData, ...rowSelectionAfc.selectedRowData]
    })
    const onReject = () => {
      if ((!rowSelectionD.selectedRowData || rowSelectionD.selectedRowData.length === 0) &&
        (!rowSelectionAfc.selectedRowData || rowSelectionAfc.selectedRowData.length === 0)) {
        message.warning('Please select the data first');
      } else {
        approvalType.value = 'reject'
        approvalModalVisible.value = true
        }
    }
    const onApprove = () => {
      if ((!rowSelectionD.selectedRowData || rowSelectionD.selectedRowData.length === 0) &&
        (!rowSelectionAfc.selectedRowData || rowSelectionAfc.selectedRowData.length === 0)) {
        message.warning('Please select the data first');
      } else {
        approvalType.value = 'approve'
        approvalModalVisible.value = true
      }
    }
    const handleCommentsConfirm = (comments: string, next: Function) => {
      const closeModal = () =>{
        approvalModalVisible.value = false
        next()
        clearSelection()
      }
      if (approvalType.value == 'reject'){
        console.log('selectedRowKeysAll.value',selectedRowKeysAll.value)
        state.selectedRowKeys = selectedRowKeysAll.value as number[]
        state.selectedRows = selectedRowsAll.value
        console.log('state.selectedRowKeys.value',state.selectedRowKeys)
        state.rejectComment = comments
        handleReject().then(() => {
          tableDataD()
          tableDataAfc()
          closeModal()
          Modal.info({
            title: 'Tips',
            content: 'Successfully Reject！'//res.data.message
          })
        }).catch((res) => {
          closeModal()
          Modal.error({
            title: 'Tips',
            content: res.message
          })
        })
      }
      if (approvalType.value == 'approve'){
        state.selectedRowKeys= selectedRowKeysAll.value as number[]
        state.selectedRows = selectedRowsAll.value
        state.approveComment = comments
        handleApprove().then(() => {
          tableDataD()
          tableDataAfc()
          closeModal()
          Modal.info({
            title: 'Tips',
            content: 'Successfully Approve！'//res.data.message
          })
        }).catch(res => {
          closeModal()
          Modal.error({
            title: 'Tips',
            content: res.message
          })
        })
      }
    };
    //#endregion
//#region [pendingDealer PayoutRound跳转功能区]
    const router = useRouter()
    const toDealerPayoutoverview: any = (payoutRound: string)=>{
      router.push({path: '/paymentApproveMgmt/dealerPayoutOverview',  query: { payoutRound: payoutRound,key: '1'}})
    }
//#endregion
const tableSize = elementSize('.ant-table-wrapper')
    watch(approveVisible, () => {
      nextTick().then(() => {
        const elementDom: HTMLElement|null = document.querySelector('.ant-table-wrapper')
        if (elementDom) {
          tableSize.value.width = elementDom.offsetWidth
          tableSize.value.height = elementDom.offsetHeight
        }
      })
    })

// type ApproveData = {
//   title: string;
//   content: string;
// }


// approveList.value = [
//   [
//     {
//       title: 'BU Central E4',
//       content: '2020.02.02 User A Comments: XXXXXXXXX'
//     }
//   ],
//   [
//     {
//       title: 'Legal staff',
//       content: 'Pending Approve 2days'
//     },
//     {
//       title: 'Compliance staff',
//       content: '2020.02.03 User B Comments: XXXXXXXXX'
//     }
//   ],
//   [
//     {
//       title: '',
//       content: ''
//     }
//   ],
//   [
//     {
//       title: '',
//       content: ''
//     }
//   ]
// ]
// approveList.value = [
//   {
//     sequenceIndex: '1',
//     // approvaled approvaling
//     approvalStatus: 'approvaled',
//     roleDtoList: [
//       {
//         roleName: 'BU Central E4',
//         flowUser: {
//           userName: '2020.02.02 User A',
//           comment: 'XXXXXXXXX'
//         }
//       }
//     ]
//   },
//   {
//     sequenceIndex: '2',
//     // approvaled approvaling
//     approvalStatus: 'approvaling',
//     roleDtoList: [
//       {
//         roleName: 'Legal staff',
//         pendingApproval: '2days'
//       },
//       {
//         roleName: 'Compliance staff',
//         flowUser: {
//           userName: '2020.02.02 User B',
//           comment: 'XXXXXXXXX'
//         }
//       }
//     ]
//   },
//   {
//     sequenceIndex: '3',
//     // approvaled approvaling
//     approvalStatus: ''
//   },
//   {
//     sequenceIndex: '4',
//     // approvaled approvaling
//     approvalStatus: ''
//   }
// ]
//#region [return部分]
    return {
      penddingAppNum,
      tabName1,
      rowSelectionD,
      rowSelectionAfc,
      selectedRowData,
      selectedRowKeysAll,
      columnsMBAFCandMBLC,
      columnsDealer,
      ...toRefs(state),
      getApprovealTableDataMBAFCandMBLC,
      getApprovealTableDataDealer,
      tableDataDealer,
      tableDataMBAFCandMBLC,
      totalAFC,
      totalD,
      toDealerPayoutoverview,
      approvalModalVisible,
      onReject,
      onApprove,
      approvalType,
      clearSelection,
      tableSize,
      handleCommentsConfirm,
      toThousands,
      toThousandsAndTwoDecimal,
      handleReject,
      handleApprove,
      downloadDealerPayoutCodeFile,
      downloadAFCPayoutCodeFile,
      approveVisible,
      approveClick,
      approveList
    }
//#endregion
  }
})
