
import { defineComponent, watch, ref } from "vue";
import useModel from "@/hooks/useModel";
// import { ApproveNode, Role } from "@/views/Approve/MgRoleApprove/PackageDetail/types";

export default defineComponent({
  name: "ApproveSteps",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup(props) {
    interface Role {
      rowName: string;
      pendingApproval?: string | null;
      approvalStatus: string;
      approvalTime: string;
      comment: string;
      userName: string;
    }
    interface ApproveNode {
      approvalStatus: string;
      sequenceIndex: string;
      roleDtoList: Role[];
    }
    //是否展开
    const isOpenSteps = useModel(props, "visible");
    // watch(() => props.visible, newVal => {
    //     isOpenSteps.value = newVal
    // })
    const toggleOpen = (): void => {
      isOpenSteps.value = !isOpenSteps.value;
    };
    //根据状态来获取对应的class
    const getStatusMappingClass = (data: ApproveNode, role: Role): string => {
      if (data.approvalStatus === "approved") {
        return "approve";
      }
      if (data.approvalStatus === "approvaling") {
        if (
          (role.userName || role.approvalTime || role.comment) &&
          role.approvalStatus === "approved"
        ) {
          return "ongoing-approve-approved";
        } else if (
          ((role.userName || role.approvalTime || role.comment) &&
            role.approvalStatus === "approvaling") ||
          role.pendingApproval
        ) {
          return "ongoing-approve";
        }
      }
      return "";
    };
    // 测试数据
    // const _data = ref<any[]>([])
    // for (let i = 0; i < 5; i++) {
    //     _data.value.push({
    //         approvalStatus: "approvaling",
    //         sequenceIndex: i+1,
    //         roleUsers: [{
    //             approvalStatus: "approvaling",
    //             approvalTime: null,
    //             comment: null,
    //             pendingApproval: "2",
    //             roleName: "Finacingchenxu001",
    //             userName: "chenxu1",

    //         }]
    //     })
    // }
    return {
      isOpenSteps,
      toggleOpen,
      getStatusMappingClass,
      noOperation: ["approved", "rejected"],
      operation: ["approved", "rejected", "withdraw"]
    };
  },
});
