import { ref, reactive } from "vue";
import { MBAFCInitSelectedDataForSearch, ApprovealTableData,AttachmentData, RowSelectionData} from "@/views/Payment/Mgmt/types";
import * as API from "@/API/payment";
import { downloadFromStream } from '@/utils/payment/downloadFile'
import { ApproveData } from '@/views/Payment/types'
import { message } from 'ant-design-vue';
import store from "@/store";

const useApprovalMain = () => {
    const state = reactive({
        selectedRowKeys: ref<number[]>(), // Check here to configure the default column
        selectedRows: ref<ApprovealTableData[]>([]),
        status: '',
        approveComment:'',
        rejectComment:'',
       /* pageNumber:1,
        pageSize:10,*/
        userName: ''
    });
    // 筛选面板
    const activeKey = ref<string>("")
    // 校验列表
    const tableDataDealer = ref<ApprovealTableData[]>([])
    const tableDataMBAFCandMBLC = ref<ApprovealTableData[]>([])
    const totalD = ref<number>(0)
    const totalAFC = ref<number>(0)
    // 上传的附件列表
    const attachmentData = ref<AttachmentData[]>([])

    // 是否显示失败原因面板
    const showFailFlag = ref<boolean>(false)
    // 状态下拉框
    //const approvalStatusArray = ref<DealerStatusData[]>([])
    // 激励适用性
    // const rebateFlagArray = ref<DealerStatusData[]>([])
    // 批量查询
    const batchQuery = reactive({
        batchQueryTitle: '',
        batchQueryModal: false
    })
    const columnsDealer = reactive([
        { title: "Payout Round", dataIndex: "payoutRound",slots: { customRender: 'payoutRound' },width: 180},
        { title: "Payout Code", dataIndex: "payoutCode",slots: { customRender: 'payoutCode' }, key: "payoutCode", width: 180},
        { title: "Program No.", dataIndex: "programNo", key: "programNo",slots: { customRender: 'programNo' }, width: 100, align: 'right'},//
        { title: "Dealer No.", dataIndex: "dealerNo", key: "dealerNo",slots: { customRender: 'dealerNo' }, width: 100, align: 'right'},//
        { title: "Payout Amount w/o VAT", dataIndex: "payoutAmountWVoat",slots: { customRender: 'payoutAmountWVoat' },  width: 180, align: 'right'},//
        { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat",slots: { customRender: 'payoutAmountWVat' },  width: 160, align: 'right'},//
        { title: "Submission Date", dataIndex: "submissionDate", width: 120, key: "retailDate", align: 'center'},
        { title: "Approval Path", fixed: 'right',  align: 'left',width: 120, slots: { customRender: "action"} },
    ])
    const columnsMBAFCandMBLC = reactive([
        { title: "Payout Round", dataIndex: "payoutRound",slots: { customRender: 'payoutRound' }, width: 130},
        { title: "Payout Code", dataIndex: "payoutCode", slots: { customRender: 'payoutCode' },width: 200},
        { title: "Program No.", dataIndex: "programNo", slots: { customRender: 'programNo' }, width: 100, align: 'right' },//
        { title: "VIN No.", dataIndex: "vinNo", slots: { customRender: 'vinNo' },width: 100, align: 'right' },//
        { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat", slots: { customRender: 'payoutAmountWVat' },  width: 160, align: 'right'},//
        { title: "Submission Date", dataIndex: "submissionDate", width: 120, key: "retailDate", align: 'center'},
        { title: "Approval Path", fixed: 'right',  align: 'left', width: 120, slots: { customRender: 'action' }},
    ])
    // 查询区数据：包括 Year|Payout Round|Payount Status
    const initSelectedDataForS = reactive<MBAFCInitSelectedDataForSearch>({
        year:['2099'],
        payoutRound:['uuu'],
        status:[{code:'33',value:'TEST'}]
    });
    // 发票/行驶证 查看弹窗
    const pictureModal = reactive({
        pictureModalFlag: false,
        pictureModalTitle: "",
        pictureModalImg: "",
    });
    // 筛选条件
    const queryParams = reactive({
        /*year:"",
        payoutCode:"",
        payStatus:"",
        currentPage:1,
        pageSize:20*/
    });
    //获取MBAFC查询区域的数据
    const getMBAFCInitSelectedDataForSearch = async () => {
        const res = await API.getInitSelectedDataForSearch()
        const statusTemp = res.status
        const statusR = statusTemp.map((value: string,key: string,arr: Array<any>) => {
            const statusObj = Object.assign({},value)
            statusObj['code'] = value['value']
            statusObj['value'] = value['value']
            return statusObj
        })
        console.log('返回查询区下拉框数据：',res)
        initSelectedDataForS.year = res.year
        initSelectedDataForS.payoutRound = res.payoutRound;
        initSelectedDataForS.status = statusR;
        state.status = initSelectedDataForS.status[0].value
    }
    //点击查询区“年”，获取payoutRound数据
    const getPayountRoundByYearForSearch = async(year: string) => {
        const res = await API.getPayountRoundByYearForSearch({
            params: {
                year
            }
        })
        initSelectedDataForS.payoutRound = res;
    }
    //获取审批菜单下主页面数据-Dealer
    const getApprovealTableDataDealer = () => {
        const params = {
          pageNumber: 1,
          pageSize: 10000,
          userId: store.state.user.usrid,
          typeList: []
        }
        API.getDealerPendingList(params).then((res) => {
            console.log('获取审批菜单下主页面数据-Dealer：',res)
            tableDataDealer.value = res.content;
            totalD.value = res.content.length
        });
    }
    //获取审批菜单下主页面数据-MBAFCandMBLC
    const getApprovealTableDataMBAFCandMBLC = () => {
        const params = {
          pageNumber: 1,
          pageSize: 10000,
          userId: store.state.user.usrid,
          typeList: []
        }
        API.getFinancePendingList(params).then((res) => {
            console.log('获取审批菜单下主页面数据-MBAFCandMBLC：',res)
            tableDataMBAFCandMBLC.value = res.content;
            totalAFC.value = res.content.length;
        });
    }

    const getUploadFileData = (id: string) =>{
        return API.getUploadFileData({params:{
                paymentId:id
            }}).then((res)=>{
            console.log('返回附件列表：',res)
            attachmentData.value = res
            return attachmentData.value = res
        });
    }
    const downloadImg = () => {
        const imgUrl = `data:image/jpg;base64,${pictureModal.pictureModalImg}`;
        // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
        if (window.navigator.msSaveOrOpenBlob) {
            const bstr = atob(imgUrl.split(",")[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            const blob = new Blob([u8arr])
            window.navigator.msSaveOrOpenBlob(blob, `${pictureModal.pictureModalTitle}.jpg`)
        } else {
            // 这里就按照chrome等新版浏览器来处理
            const a = document.createElement("a");
            a.href = imgUrl;
            a.setAttribute("download", `${pictureModal.pictureModalTitle}.jpg`)
            a.click();
        }
    };
    const mulSearchEvent = (rowSelection: any) => {
        if (!state.status){
            state.status = "all"
        }
       // state.pageNumber = 1
        rowSelection.selectedRowKeys = []
        rowSelection.selectedRowData = []
        //getTableData()
    }
    const pageChange = (page: number, pageSize: number,rowSeclection: RowSelectionData) => {
        // state.pageNumber = page > 0 ? page : 1
        // state.pageSize = pageSize;
        rowSeclection.selectedRowKeys = []
        //getTableData()
    };
    const sizeChange = (page: number, pageSize: number,rowSelection: RowSelectionData) => {
        // state.pageNumber = page > 0 ? page : 1
        // state.pageSize = pageSize;
        rowSelection.selectedRowKeys = []
        //getTableData()
    };
    const handleClick = (event: MouseEvent) => {
        activeKey.value = ""
        event.stopPropagation();
    };
    const handleReject = () => {
        return new Promise((resolve, reject) => {
            API.fetchPaymentReject(state.selectedRows.map(item => {
              return {
                rejectComment: state.rejectComment,
                delegationUserId: item.delegationUserId,
                id: item.id,
                requestUserId: item.requestUserId
              }
            })).then(res=>{
                if (res.code == '0'){
                    resolve(res)
                }
                else {
                    reject(res)
                }
            }).catch((error: any) => {
                reject(error)
            })
        })
    }
    const handleApprove = () => {
        return new Promise((resolve, reject) => {
            API.fetchPaymentApprove(state.selectedRows.map(item => {
              return {
                approveComment: state.approveComment,
                delegationUserId: item.delegationUserId,
                id: item.id,
                requestUserId: item.requestUserId
              }
            })).then(res=>{
                if (res.code == '0'){
                    resolve(res)
                }
                else {
                    reject(res)
                }
            }).catch((error: any) => {
                reject(error)
            })
        })
    }

    const downloadDealerPayoutCodeFile = (payoutCode: string) => {
      API.downloadPendingDealerPayoutCodeFile(payoutCode).then((res: any) => {
        downloadFromStream(res)
      })
    }

    const downloadAFCPayoutCodeFile = (payoutCode: string) => {
      API.downloadPendingAFCPayoutCodeFile(payoutCode).then((res: any) => {
        downloadFromStream(res)
      })
    }

    const approveList = ref<ApproveData[]>()
    const approveVisible = ref(false)
    const approveClick = (record: any, type: string) => {
        approveList.value = []
        const params = {
            approvalType: "PayoutApproval",
            payoutCodeId: record.id,
            payoutType: type
        }
        API.getApprovalPackageByCreated(params).then((res: any) => {
            if (res.code === '0') {
                approveList.value = res.data.map((item: any) => item.nodeList)
                approveVisible.value = true
            } else {
                message.error(res.message)
            }
        })
    }

    return {
        getApprovealTableDataDealer,
        getApprovealTableDataMBAFCandMBLC,
        //resetEvent,
        mulSearchEvent,
        downloadImg,
        pageChange,
        sizeChange,
        handleClick,
        getMBAFCInitSelectedDataForSearch,
        initSelectedDataForS,
        getPayountRoundByYearForSearch,
        getUploadFileData,
        columnsMBAFCandMBLC,
        columnsDealer,
        attachmentData,
        queryParams,
        tableDataDealer,
        tableDataMBAFCandMBLC,
        totalAFC,
        totalD,
        pictureModal,
        activeKey,
        showFailFlag,
        batchQuery,
        state,
        handleApprove,
        handleReject,
        downloadDealerPayoutCodeFile,
        downloadAFCPayoutCodeFile,
        approveVisible,
        approveList,
        approveClick
    }
}
export default useApprovalMain