/*
 * @Author: hanxi
 * @Date: 2021-06-08 15:27:45
 * @Description: 千分位符处理，处理后的数字保留两位小数
 */
export const toThousandsAndTwoDecimal = (count: number | string): string => {
  if (count === undefined || count === null) {
    return "";
  }
  if (typeof count === "string") {
    if (count.indexOf(",") !== -1) {
      if (count.indexOf(".") !== -1) {
        return count;
      } else {
        return count + ".00";
      }
    }
    count = Number(count);
  }
  count = count.toFixed(2);
  return count
    .toString()
    .replace(/(\d)(?=(\d{3})+\.)/g, function($0, $1) {
      return $1 + ",";
    })
    .replace(/\.$/, "");
};

export const toThousands = (count: number | string): string => {
  if (count === undefined) {
    return "";
  }
  if (typeof count === "string") {
    if (count.indexOf(",") !== -1) {
      if (count.indexOf(".") !== -1) {
        return count.substring(0, count.indexOf(".") + 1)
      } else {
        return count;
      }
    }
    count = Number(count);
  }
  count = Math.round(count)
  return count
    .toString()
    .replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
};