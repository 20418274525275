
import { computed, defineComponent, PropType, ref, watch, reactive } from "vue";
import { ExclamationCircleFilled } from "@ant-design/icons-vue";
export default defineComponent({
  name: "approvalModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    formatkey: {
      type: String,
      required: true,
    },
    approvalType: {
      type: String,
      default: "approve",
    },
    // placeholder: {
    //   type: String,
    //   defalut: 'Please input'
    // },
    commentsRequired: {
      type: Boolean,
      defalut: true,
    },
  },
  setup(props, context) {
    const placeholderMap = reactive({
      reject: "Please provide your comments for rejection",
      approve: "Please provide your comments for approval",
    });
    const templist = computed(() => props.list);
    const tableModalVisible = computed(() => props.visible);
    const title = computed(() =>
      props.approvalType == "approve"
        ? "Approve Payout:"
        : props.approvalType == "reject"
        ? "Reject Payout:"
        : "Approve Payout:"
    );
    const loading = ref<boolean>(false);
    const comments = ref<string | null>(null);
    watch(templist, (newV) => {
      console.log("templistwwww=====", newV);
    });
    /*watch(
        () => props.visible,
      (newVal) => {
        console.log(typeof newVal, "watch");
        tableModalVisible.value = newVal;
      },{
          immediate:true
        });
    watch([props.visible, props.approveType], (newValues, prevValues) => {
      console.log(newValues, prevValues,'进入弹框侦听中。。');
      console.log(typeof newValues[0])
      tableModalVisible.value = newValues[0]
    },{
      immediate:true
    })*/
    const handleCancel = () => {
      //tableModalVisible.value = false;
      context.emit("update:visible", false);
      comments.value = ''
    };
    const handleConfirm = () => {
      loading.value = true;
      context.emit("on-confirm", comments.value, () => {
        comments.value = null;
        loading.value = false;
      });
    };
    return {
      handleCancel,
      handleConfirm,
      tableModalVisible,
      loading,
      comments,
      title,
      placeholderMap
    };
  },
  components: {
    ExclamationCircleFilled,
  },
});
