import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e0b0b03"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collapse-wrapper" }
const _hoisted_2 = { class: "collapse-header" }
const _hoisted_3 = { class: "collapse-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaretRightOutlined = _resolveComponent("CaretRightOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true),
      _createVNode(_component_CaretRightOutlined, {
        class: _normalizeClass(["collapse-icon", _ctx.visible ? 'down' : 'right']),
        onClick: _ctx.handleToggle
      }, null, 8, ["class", "onClick"])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ], 512), [
      [_vShow, _ctx.visible]
    ])
  ]))
}